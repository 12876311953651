import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSplash from 'vue-splash'

import './permission' // permission control

Vue.config.productionTip = false
Vue.use(VueSplash)

new Vue({
    router,
    store,
    vuetify,
    created() {
        return (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) {
                return
            }
            js = d.createElement(s)
            js.id = id
            js.src = '//connect.facebook.com/en_US/messenger.Extensions.js'
            fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'Messenger')
    },
    render: (h) => h(App)
}).$mount('#app')
