var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"app-container"},[_c('v-card',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay),expression:"overlay"}],staticClass:"overlay"},[_c('div',{staticClass:"loading-message"},[_c('span',{staticStyle:{"color":"white","font-size":"14px"}},[_vm._v(_vm._s(_vm.overlayText))])])]),_c('v-row',{staticClass:"banner-container",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"10"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"banner","src":require("@/assets/images/logo.jpg")},on:{"load":_vm.onImg1Load}})]),_c('v-col',{attrs:{"cols":"1"}})],1),_c('v-container',{staticClass:"content-container"},[_c('v-row',{staticClass:"progress-nav"},[_c('v-col',{staticClass:"progress-nav-item",class:{ active: _vm.$route.name == 'Calculator' },staticStyle:{"padding":"0px !important"},attrs:{"cols":"3"},on:{"click":_vm.handleCalculator}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Calculator'),expression:"$route.name != 'Calculator'"}],attrs:{"cols":"3"}}),_c('v-col',[_c('img',{style:(_vm.$route.name == 'Calculator'
                                        ? 'width: 43.75px !important; height: 43.75px !important;'
                                        : 'width: 31.25px !important; height: 31.25px !important;'),attrs:{"src":require("@/assets/images/calculating.svg")},on:{"load":_vm.onImg2Load}})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'Profile'),expression:"$route.name == 'Profile'"}],staticStyle:{"padding-left":"10px !important"},attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Calculator' && _vm.$route.name != 'Profile'),expression:"$route.name != 'Calculator' && $route.name != 'Profile'"}],attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('span',{staticClass:"nav-text"},[_vm._v("Calculator")])])],1),_c('v-col',{staticClass:"progress-nav-item",class:{ active: _vm.$route.name == 'Profile' },staticStyle:{"padding":"0px !important"},attrs:{"cols":"3"},on:{"click":_vm.handleProfile}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'Calculator'),expression:"$route.name == 'Calculator'"}],staticStyle:{"padding-right":"10px !important"},attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Calculator' && _vm.$route.name != 'Profile'),expression:"$route.name != 'Calculator' && $route.name != 'Profile'"}],attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',[_c('img',{style:(_vm.$route.name == 'Profile'
                                        ? 'width: 43.75px !important; height: 43.75px !important;'
                                        : 'width: 31.25px !important; height: 31.25px !important;'),attrs:{"src":require("@/assets/images/man.svg")},on:{"load":_vm.onImg3Load}})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'Address'),expression:"$route.name == 'Address'"}],staticStyle:{"padding-left":"10px !important"},attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Address' && _vm.$route.name != 'Profile'),expression:"$route.name != 'Address' && $route.name != 'Profile'"}],attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('span',{staticClass:"nav-text"},[_vm._v("Profile")])])],1),_c('v-col',{staticClass:"progress-nav-item",class:{ active: _vm.$route.name == 'Address' },staticStyle:{"padding":"0px !important"},attrs:{"cols":"3"},on:{"click":_vm.handleAddress}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'Profile'),expression:"$route.name == 'Profile'"}],staticStyle:{"padding-right":"10px !important"},attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Profile' && _vm.$route.name != 'Address'),expression:"$route.name != 'Profile' && $route.name != 'Address'"}],attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',[_c('img',{style:(_vm.$route.name == 'Address'
                                        ? 'width: 43.75px !important; height: 43.75px !important;'
                                        : 'width: 31.25px !important; height: 31.25px !important;'),attrs:{"src":require("@/assets/images/house.svg")},on:{"load":_vm.onImg4Load}})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'Attachment'),expression:"$route.name == 'Attachment'"}],staticStyle:{"padding-left":"10px !important"},attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Attachment' && _vm.$route.name != 'Address'),expression:"$route.name != 'Attachment' && $route.name != 'Address'"}],attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('span',{staticClass:"nav-text"},[_vm._v("Address")])])],1),_c('v-col',{staticClass:"progress-nav-item",class:{ active: _vm.$route.name == 'Attachment' },staticStyle:{"padding":"0px !important"},attrs:{"cols":"3"},on:{"click":_vm.handleAttachment}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'Address'),expression:"$route.name == 'Address'"}],staticStyle:{"padding-right":"10px !important"},attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Attachment' && _vm.$route.name != 'Address'),expression:"$route.name != 'Attachment' && $route.name != 'Address'"}],attrs:{"cols":"3"}},[_c('span',{staticClass:"dot"})]),_c('v-col',[_c('img',{style:(_vm.$route.name == 'Attachment'
                                        ? 'width: 43.75px !important; height: 43.75px !important;'
                                        : 'width: 31.25px !important; height: 31.25px !important;'),attrs:{"src":require("@/assets/images/attachment.svg")},on:{"load":_vm.onImg5Load}})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Attachment'),expression:"$route.name != 'Attachment'"}],attrs:{"cols":"3"}})],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('span',{staticClass:"nav-text"},[_vm._v("Attachment")])])],1)],1),_c('app-main')],1)],1),_c('vue-splash',{attrs:{"show":!_vm.showMain,"logo":_vm.logo,"color":"#F5A847","size":150,"fixed":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }