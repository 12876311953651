import { resetRouter } from '@/router'

const getDefaultState = () => {
    return {
        calculatorInfo: {},
        profileInfo: {},
        addressInfo: {},
        attachmentInfo: {},

        // form validation
        calculatorFormIsValid: false,
        profileFormIsValid: false,
        addressFormIsValid: false,
        attachmentFormIsValid: false,

        // overlay
        overlay: false,
        overlayText: '',

        error_files: [],

        resubmit: false,
        submitted: false,

        fb_fname: '',
        fb_lname: '',
        fb_messengerid: '',
        fb_gender: '',
        fb_profilepic: ''
    }
}

const state = getDefaultState()

const mutations = {
    SET_CALCULATOR_INFO: (state, calculatorInfo) => {
        state.calculatorInfo = calculatorInfo
    },
    SET_PROFILE_INFO: (state, profileInfo) => {
        state.profileInfo = profileInfo
    },
    SET_ADDRESS_INFO: (state, addressInfo) => {
        state.addressInfo = addressInfo
    },
    SET_ATTACHMENT_INFO: (state, attachmentInfo) => {
        state.attachmentInfo = attachmentInfo
    },

    SET_CALCULATOR_FORM_IS_VALID: (state, calculatorFormIsValid) => {
        state.calculatorFormIsValid = calculatorFormIsValid
    },
    SET_PROFILE_FORM_IS_VALID: (state, profileFormIsValid) => {
        state.profileFormIsValid = profileFormIsValid
    },
    SET_ADDRESS_FORM_IS_VALID: (state, addressFormIsValid) => {
        state.addressFormIsValid = addressFormIsValid
    },
    SET_ATTACHMENT_FORM_IS_VALID: (state, attachmentFormIsValid) => {
        state.attachmentFormIsValid = attachmentFormIsValid
    },

    SET_OVERLAY: (state, overlay) => {
        state.overlay = overlay
    },

    SET_OVERLAY_TEXT: (state, overlayText) => {
        state.overlayText = overlayText
    },

    SET_RESUBMIT: (state, resubmit) => {
        state.resubmit = resubmit
    },

    SET_SUBMITTED: (state, submitted) => {
        state.submitted = submitted
    },

    SET_FB_INFO: (state, fbInfo) => {
        state.fb_fname = fbInfo.fb_fname
        state.fb_lname = fbInfo.fb_lname
        state.fb_messengerid = fbInfo.fb_messengerid
        state.fb_gender = fbInfo.fb_gender
        state.fb_profilepic = fbInfo.fb_profilepic
    }
}

const actions = {
    // all the pages

    // calculator
    calculator({ commit }, calculatorInfo) {
        return new Promise((resolve) => {
            commit('SET_CALCULATOR_INFO', calculatorInfo)
            resolve()
        })
    },

    // profile
    profile({ commit }, profileInfo) {
        return new Promise((resolve) => {
            commit('SET_PROFILE_INFO', profileInfo)
            resolve()
        })
    },

    // addressInfo
    address({ commit }, addressInfo) {
        return new Promise((resolve) => {
            commit('SET_ADDRESS_INFO', addressInfo)
            resolve()
        })
    },

    // attachment
    attachment({ commit }, attachmentInfo) {
        return new Promise((resolve) => {
            commit('SET_ATTACHMENT_INFO', attachmentInfo)
            resolve()
        })
    },

    // calculator form valid
    calculatorFormIsValid({ commit }, status) {
        return new Promise((resolve) => {
            commit('SET_CALCULATOR_FORM_IS_VALID', status)
            resolve()
        })
    },

    // profile form valid
    profileFormIsValid({ commit }, status) {
        return new Promise((resolve) => {
            commit('SET_PROFILE_FORM_IS_VALID', status)
            resolve()
        })
    },

    // address form valid
    addressFormIsValid({ commit }, status) {
        return new Promise((resolve) => {
            commit('SET_ADDRESS_FORM_IS_VALID', status)
            resolve()
        })
    },

    // attachment form valid
    attachmentFormIsValid({ commit }, status) {
        return new Promise((resolve) => {
            commit('SET_ATTACHMENT_FORM_IS_VALID', status)
            resolve()
        })
    },

    // overlay
    overlay({ commit }, overlay) {
        return new Promise((resolve) => {
            commit('SET_OVERLAY', overlay)
            resolve()
        })
    },

    // overlayText
    overlayText({ commit }, overlayText) {
        return new Promise((resolve) => {
            commit('SET_OVERLAY_TEXT', overlayText)
            resolve()
        })
    },

    // resubmit
    resubmit({ commit }, resubmit) {
        return new Promise((resolve) => {
            commit('SET_RESUBMIT', resubmit)
            resolve()
        })
    },

    // submitted
    submitted({ commit }, submitted) {
        return new Promise((resolve) => {
            commit('SET_SUBMITTED', submitted)
            resolve()
        })
    },

    // Set fb info
    fbInfo({ commit }, fbInfo) {
        return new Promise((resolve) => {
            commit('SET_FB_INFO', fbInfo)
            resolve()
        })
    },

    // user logout
    logout() {
        return new Promise((resolve) => {
            resetRouter()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
